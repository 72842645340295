import store from '@/store';

const getUserName = function (name) {
    if (name.length > 10) {
        return name.substring(0, 10) + '..';
    } else {
        return name;
    }
};

const getMenu = function () {
    const user = store.getters.currentUser;

    const adminMenu = [
        {
            icon : 'fa fa-tachometer',
            text : 'Dashboard',
            link : '/app/'
        },
        {
            icon  : 'fa fa-database',
            text  : 'Admin Master Data',
            link  : '#',
            items : [
                {
                    text : 'Master Data',
                    link : '/app/master-data/'
                },
                {
                    text : 'Profile Master Data',
                    link : '/app/profile-master-data/'
                },
                {
                    text : 'Role',
                    link : '/app/role/'
                },
                {
                    text : 'Terms and Conditions',
                    link : '/app/terms/'
                }
            ]
        },
        {
            icon : 'fa fa-building',
            text : 'Company',
            link : '/app/company-list/'
        },
        {
            icon : 'fa fa-newspaper-o',
            text : 'Standard Templates',
            link : '/app/standard-templates/'
        },
        {
            icon  : 'fa fa-desktop',
            text  : 'Website',
            link  : '#',
            items : [
                {
                    text : 'Training Video',
                    link : '/website/training-video/'
                },
                {
                    text : 'Knowledge Hub',
                    link : '/website/knowledge-hub/'
                }
            ]
        },
        {
            icon  : 'fa fa-file-archive-o',
            text  : 'Reports',
            link  : '#',
            items : [
                {
                    text : 'User Reports',
                    link : '/app/user-reports/'
                },
                {
                    text : 'User Acquisition Reports',
                    link : '/app/user-acquisition-reports/'
                },
                {
                    text : 'My Template Reports',
                    link : '/app/my-template-reports/'
                },
                {
                    text : 'Standard Template Reports',
                    link : '/app/standard-template-reports/'
                },
                {
                    text : 'Company Reports',
                    link : '/app/company-reports/'
                }
            ]
        },
        {
            type : 'separator'
        },
        {
            icon              : 'fa fa-user-o',
            text              : getUserName(user.name),
            title             : user.name,
            dropdownPlacement : 'right',
            link              : '#',
            items             : [
                {
                    text : 'Change Password',
                    link : '/app/change-password/'
                },
                {
                    text : 'Logout',
                    link : '/app/logout/'
                }
            ]
        }
    ];
    const menu = [
        {
            icon : 'fa fa-tachometer',
            text : 'Dashboard',
            link : '/app/'
        },
        {
            icon : 'fa fa-users',
            text : 'Users',
            link : '/app/company-user-list/'
        },
        {
            icon  : 'fa fa-pencil-square-o',
            text  : 'Tender',
            link  : '#',
            items : [
                {
                    text : 'Tenders',
                    link : '/app/tender/'
                },
                {
                    text : 'Archived Tenders',
                    link : '/app/archived/tender/'
                }
            ]
        },
        {
            icon : 'fa fa-retweet',
            text : 'My Contacts',
            link : '/app/my-contacts/'
        },
        {
            icon : 'fa fa-check-square-o',
            text : 'My Bid',
            link : '/app/my-bid/'
        },
        {
            icon  : 'fa fa-newspaper-o',
            text  : 'Templates',
            link  : '#',
            items : [
                {
                    text : 'My Templates',
                    link : '/app/my-template/'
                },
                {
                    text : 'Standard Templates',
                    link : '/app/standard-templates/'
                }
            ]
        },
        {
            icon : 'fa fa-bell-o',
            text : 'Notification',
            link : '/app/message/'
        },
        {
            icon  : 'fa fa-file-archive-o',
            text  : 'Reports',
            link  : '#',
            items : [
                {
                    text : 'User Reports',
                    link : '/app/consumer/user-reports/'
                },
                {
                    text : 'Tender Reports',
                    link : '/app/consumer/tender-reports/'
                },
                {
                    text : 'Bid Reports',
                    link : '/app/consumer/bid-reports/'
                },
                {
                    text : 'My Template Reports',
                    link : '/app/consumer/my-template-reports/'
                },
                {
                    text : 'My Contacts',
                    link : '/app/consumer/my-contact-reports/'
                },
                {
                    text : 'Standard Template Reports',
                    link : '/app/consumer/standard-template-reports/'
                }
            ]
        },
        {
            type : 'separator'
        },
        {
            icon              : 'fa fa-user-o',
            text              : getUserName(user.name),
            title             : user.name,
            link              : '#',
            dropdownPlacement : 'right',
            items             : [
                {
                    text : 'Profile',
                    link : '/app/my-profile/'
                },
                {
                    text : 'Change Password',
                    link : '/app/change-password/'
                },
                {
                    text : 'Logout',
                    link : '/app/logout/'
                }
            ]
        }
    ];

    if (user.admin === true) {
        return [
            ...adminMenu
        ];
    }
    return [
        // return menu
        ...menu
    ];
};
export default getMenu;
